import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../toast/toast.service';
import { Observable } from 'rxjs';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { LoadingService } from '../loading/loading.service';
import { UserService } from 'src/app/user/services/user.service';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { HomeService } from 'src/app/home/services/home.service';

firebase.initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: 'root'
})
export class PageService extends BaseService {

  userId: number;
  public static notificationCount: number = 0;
  messageList: any = [];
  eventList: any = [];

  constructor(http: HttpClient, public toastService: ToastService, public authHelperService: AuthHelperService,
    public loadingService: LoadingService, public userService: UserService, public navCtrl: NavController,
    public modalController: ModalController, public router: Router, public homeService: HomeService) {
    super(http);
  }

  getPageData(id) {
    return super.get<any>('page/' + id, false);
  }

  getSiteInfo(): Promise<any> {
    return new Promise(resolve => {
      if (this.getValue('siteinfo')) {
        resolve(this.getValue('siteinfo'));
      } else {
        super.get<any>('siteinfo', false).subscribe(value => {
          this.storeValue(value, 'siteinfo');
          resolve(value);
          return value;
        }, error => {
          this.toastService.presentToast({ message: error.error.message, status: 'error' });
        });
      }
    })
  };

  loadImages(images): Promise<any> {
    return new Promise(resolve => {
      images.forEach(function (img) {
        var images = new Image();
        images.src = img.atta_url;
      });
      resolve('images loaded');
    });
  }

  storePageData(pageId): Promise<any> {
    return new Promise(resolve => {
      if (this.getValue('page' + pageId)) {
        resolve(this.getValue('page' + pageId));
      } else {
        this.getPageData(pageId).subscribe(value => {
          value.page_component.forEach(function (img) {
            if (img.paComponentType == 'image' || img.paComponentType == 'bg-image') {
              var images = new Image();
              images.src = img.image.atta_url;
            }
          });
          this.storeValue(value, 'page' + pageId);
          resolve(value);
        }, error => {
          this.toastService.presentToast({ message: error.error.message, status: 'error' });
        });
      }
    });
  }

  //stallassets
  // preLogin(pageId) {
  //   this.getPageData(pageId).subscribe(value => {
  //     value.page_component.forEach(function (img) {
  //       if (img.paComponentType == 'image' || img.paComponentType == 'bg-image') {
  //         var images = new Image();
  //         images.src = img.image.atta_url;
  //       }
  //     });
  //     this.storeValue(value, 'page' + pageId);
  //     return value;
  //   }, error => {
  //     this.toastService.presentToast({ message: error.error.message, status: 'error' });
  //   });
  // }


  stallassets() {
    super.get<any>('stallassets', true).subscribe(value => {
      value['data'].forEach(element => {
        var images = new Image();
        images.src = element.furl;
      });
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  getAssets(arrayVar: Array<any>, value: any) {
    let item = arrayVar.find(i => i.identifier == value);
    return (item != undefined) ? item.atta_url : false;
  }

  storeValue(data, name) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  getValue(name) {
    var val = localStorage.getItem(name);
    if (val != 'undefined' && val != undefined && val != null) return JSON.parse(localStorage.getItem(name));
    else return val;
  }

  removeValue(name) {
    localStorage.removeItem(name);
  }

  getProfile(id) {
    return super.get<any>('getuser/' + id, true);
  }

  getUserProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.getValue('userInfo') != null) {
        resolve(this.getValue('userInfo'));
      }
      else {
        this.userId = this.authHelperService.getUserId();
        this.getProfile(this.userId).subscribe(value => {
          this.storeValue(value.data, 'userInfo');
          resolve(value.data);
        }, error => {
          this.toastService.presentToast({ message: error.error.message, status: 'error' });
        });
      }
    });
  }

  getHelpDesk() {
    return super.get<any>('helpdesk', true);
  }

  storeHelpdesk(data) {
    return super.post<any>('helpdesk', true, data);
  }

  sendAttachmentEmail(id) {
    this.loadingService.present();
    this.userService.emailAttachment({ id: id }).subscribe(value => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: value.message, status: 'success' });
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' })
    });
  }

  printAttachment(url) {
    let printContents, popupWin;
    var extension = url.split('.').pop();
    if (extension == 'pdf') {
      window.open(url, '_blank', 'fullscreen=yes');
    }
    else {
      printContents = (extension == 'pdf') ? `<iframe src="${url}" toolbar="no"></iframe>` : `<img src="${url}">`;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
        </head>
      <body onload="window.print();window.close()">${printContents}</body>
      </html>`
      );
      popupWin.document.close();
    }
  }

  addUser(data) {
    var userId = this.authHelperService.getUserId();
    firebase.database().ref('users').child(userId).update(data);
  }

  // Notification Start
  addNotifications(data, partnerId) {
    firebase.database().ref('notifications').child(partnerId).push(data);
  }

  showNotifications() {
    var data = [];
    firebase.database().ref('notifications').child(this.authHelperService.getUserId()).on('value', (snapshot) => {
      let event = this.getValue('event');
      var res = {}, j = 0;
      data = [];
      res = snapshot.val();
      for (let i in res) {
        if (res[i].status == 'unread' && res[i].eventId == event.id) {
          data[j++] = res[i];
          this.userService.getProfile(res[i].sender).subscribe(value => {
            res[i].key = i;
            res[i].sendername = value.data.use_name;
            if (res[i].type == "videocall") {
              this.toastService.videoNotification(res[i]);
            } else if (res[i].type == "network") {
              this.toastService.notificationToast(res[i]);
            } else {
              this.toastService.notificationToast(res[i]);
            }
            // this.updateNotifcation(res[i].sender, {status: 'read'});
          });
        }
      }
    });
  }

  updateNotifcation(sender, data) {
    firebase.database().ref('notifications').child(this.authHelperService.getUserId()).once('value', (snapshot) => {
      var res = {};
      res = snapshot.val();
      for (let i in res) {
        if (res[i].status == 'unread' && res[i].sender == sender) {
          firebase.database().ref('notifications').child(this.authHelperService.getUserId()).child(i).update(data);
        }
      }
    });
  }

  getNotificationCount() {
    firebase.database().ref('notifications').child(this.authHelperService.getUserId()).on('value', (snapshot) => {
      var res = {};
      res = snapshot.val();
      PageService.notificationCount = 0;
      let event = this.getValue('event');
      for (let i in res) {
        if (res[i].status == 'unread' && res[i].eventId == event.id) {
          ++PageService.notificationCount;
        }
      }
    });
  }

  getNotifications(type): Promise<any> {
    var data = [], data1 = [], index = 0;
    let event = this.getValue('event');
    return new Promise((resolve, reject) => {
      firebase.database().ref('notifications').child(this.authHelperService.getUserId()).once('value', (snapshot) => {
        var res = {}, j = 0;
        res = snapshot.val();
        for (let i in res) {
          res[i].key = i;
          if (type == 'unread' && res[i].status == 'unread' && res[i].eventId == event.id) {
            this.userService.getProfile(res[i].sender).subscribe(value => {
              res[i].sender = value.data;
            });
            data[j++] = res[i];
          }
          else if (type != 'unread' && res[i].eventId == event.id) {
            this.userService.getProfile(res[i].sender).subscribe(value => {
              res[i].sender = value.data;
            });
            data[j++] = res[i];
          }
        }
      });
      firebase.database().ref('notifications').child(this.authHelperService.getUserId()).orderByKey().once('value', (snapshot) => {
        this.messageList = [];
        this.messageList = snapshot.val();
        for (let i in this.messageList) {
          this.messageList[i].key = i;
          if (type == 'unread' && this.messageList[i].status == 'read' && this.messageList[i].eventId == event.id && index < 10) {
            this.userService.getProfile(this.messageList[i].sender).subscribe(value => {
              this.messageList[i].sender = value.data;
            });
            data1[index++] = this.messageList[i];
          }
        }
      });
      resolve([data, data1]);
    });
  }
  // Notification Ends

  goChatModel(val) {
    let navigationExtras: NavigationExtras = {
      state: {
        partneruid: val.sender
      }
    };
    this.navCtrl.navigateForward(['chat'], navigationExtras);
  }

  goToVideoChat(val) {
    let navigationExtras: NavigationExtras = {
      state: {
        exhibitorData: val.exhibitorData,
        eventId: val.eventId,
        curExid: val.exhibitorData.exId,
      }
    };
    this.navCtrl.navigateForward(['videochat/landing'], navigationExtras);
  }

  goToStall(val) {
    let navigationExtras: NavigationExtras = {
      state: {
        eventId: val.eventId,
        curExid: val.exhibitorId,
      }
    };
    this.navCtrl.navigateForward(['stall-chat'], navigationExtras);
  }

  async showErrorComponent(error) {
    if (error.status == 403) {
      const modal = await this.modalController.create({
        component: ErrorComponent,
        cssClass: 'popup-modal',
        backdropDismiss: false,
        componentProps: {
          message: error.error.message
        }
      });
      return await modal.present();
    }
    else {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    }
  }

  checkEvent() {
    var event = this.getValue('event');
    if (event == null || event == '') {
      this.getEventList();
      // this.router.navigate(['/home']);
    }
  }

  logout(id) {
    return super.get<any>('logout-notification/' + id, false);
  }

  getEventList() {
    this.homeService.getEventList().subscribe(value => {
      this.eventList = value.data;
      if (this.eventList.length > 0) {
        this.storeValue({ id: this.eventList[0].eveId, logo: this.eventList[0].file.atta_url, name: this.eventList[0].eveName }, 'event');
      }
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  checkLogged() {
    if (this.authHelperService.getToken()) {
      this.router.navigate(["/home"]);
      // this.router.navigate(["/lobby"]);
    }
  }

  getAdvertisements(id, eId, sId = null): Promise<any> {
    return new Promise(resolve => {
      super.get<any>('advertisment/' + id + '/' + eId + '/' + sId, true).subscribe(result => {
        var data = result.data;
        if (data.length > 0) {
          var slot = data[0].adsId;
          var isAdClosed = this.getCookie('ad' + slot);
          if (parseInt(isAdClosed) != slot) resolve(data);
          else resolve([]);
        } else resolve([]);

      }, error => {
        this.toastService.presentToast({ message: error.error.message, status: 'error' });
        resolve([]);
      });
    });
  }

  closeAdvertisement(data) {
    var slot = data[0].adsId;
    this.setCookie('ad' + slot, slot);
  }

  setCookie(name, value) {
    var expires = "";
    var date = new Date();
    date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  addViews(data) {
    return super.post<any>('views', true, data);
  }


}