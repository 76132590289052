import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/base.service';

@Injectable({
  providedIn: 'root'
})
export class FileService extends BaseService {

  constructor(http: HttpClient) { 
    super(http);
  }

  getFiles() 
  {
    return super.get<any>('file', true);
  }

  uploadFile(data) 
  {
    return super.post<any>('file', true, data);
  }

}