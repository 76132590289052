// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


var host = location.hostname;
var url = localStorage.getItem('dataSource');

export const environment = {
  production: false,
  baseUrl: url,
  bannerDefault: 'https://expoassets.ams3.digitaloceanspaces.com/iforafrika/WXeHMdv2jza4o9kbRDZY4xPIcqnG9H4gjcegWPp2.jpeg',
  firebaseConfig: {
    apiKey: "AIzaSyCt607HJVfZ19PLX1AuoGu0izb1UrCPlRk",
    authDomain: "tapma-smartvirtualexpos.firebaseapp.com",
    databaseURL: "https://tapma-smartvirtualexpos-default-rtdb.firebaseio.com",
    projectId: "tapma-smartvirtualexpos",
    storageBucket: "tapma-smartvirtualexpos.appspot.com",
    messagingSenderId: "137823911861",
    appId: "1:137823911861:web:f9e0b6f553c325e88463fc",
    measurementId: "G-CKSZBRTS87"
  }
};
 

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

