import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(http: HttpClient, public https: HttpClient) {
    super(http);
  } 

  logout()
  {
    return super.get<any>('logout', true);
  } 

  updateProfilePic(data)
  {
    return super.post<any>('profile-picture', true, data);
  }

  getProfile(id)
  {
    return super.get<any>('getuser/'+id, true);
  }

  updateProfile(data)
  {
    return super.patch<any>('user', true, data);
  }

  getMyConnentions()
  {
    return super.get<any>('my-connection', true);
  }

  getMyBrief(id)
  {
    return super.get<any>('brief/'+id, true);
  }

  deleteBrief(id)
  {
    return super.delete<any>('brief/'+id, true);
  }

  emailAttachment(data)
  {
    return super.post<any>('brief/mail', true, data);
  }

  downloadData()
  {
    return super.get<any>('download-data', true, 'blob' as 'json');
  }

  getVisitedStalls(type)
  {
    return super.get<any>('visit/'+type, true);
  }

  getExDashboard()
  {
    return super.get<any>('exhibitor-dashboard', true);
  }

  getVideoToken(){
    return super.get<any>('video/gettoken', false);
  }

  getReport(fdate, tdate) 
  {
    return super.get<any>('report/'+fdate+'/'+tdate, true);
  }
}