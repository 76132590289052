import { Component, OnInit, ViewChild, NgZone, Input, Pipe, PipeTransform } from '@angular/core';
import { ChatService } from '../../service/chat.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import * as firebase from 'firebase';
import { PageService } from 'src/app/common/services/page/page.service';
import { ConcatSource } from 'webpack-sources';
import { AngularFireDatabase } from '@angular/fire/database';
import { IonContent,  NavParams, AlertController, ActionSheetController } from '@ionic/angular';
import { EventService } from '../../service/event.service'; 


//elements


@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }

}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit {

  cuid: any;

  partnerId: any;

  message: any;

  buddymessages = [];

  users = [];
  messagesToShow = [];

  usersToShow = [];

  connectionusers = [];

  activeUser: any;
  @Input() data: string;
  @Input() partner: any;

 
  //@ViewChild('IonContent', { static: true }) IonContent: IonContent;

  @ViewChild(IonContent) contentArea: IonContent;
  
  constructor(
    public chatService: ChatService,
    private formBuilder: FormBuilder,
    public afDB: AngularFireDatabase,
    public events: EventService,
    public navCtrl: NavController,
    public ngZone: NgZone,
    private route: ActivatedRoute,  
    private modalCtrl: ModalController,
    public modalController: ModalController,
    public router: Router, public toastService: ToastService,
    public authHelperService: AuthHelperService, public pageService: PageService
  ) {



    this.route.queryParams.subscribe(params => {  
      if (this.router.getCurrentNavigation().extras.state) {
        this.partnerId = this.router.getCurrentNavigation().extras.state.partneruid.id;
        this.activeUser = this.router.getCurrentNavigation().extras.state.partneruid;
        this.getUserConections();
        this.events.publish('userstatus');
        this.events.publish('messages');
        this.getValueWithAsync();
      }  
    });

    setTimeout(() => {
      this.scrollToBottom()
    }, 10);






    var userRef = firebase.database().ref('users').child(this.authHelperService.getUserId());
    window.onload = resetTimer;
    //window.onmousemove = resetTimer; 
    window.onmousedown = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    window.onkeypress = busy;
    var time;

    function away() {
      userRef.set({
        date: Date.now(),
        "status": 'idle',
      });
    }
    function busy() {
      userRef.set({
        date: Date.now(),
        "status": 'busy',
      });
    }
    function resetTimer() {

      clearTimeout(time);

      userRef.set({
        date: Date.now(),
        "status": 'online',
      });

      time = setTimeout(away, 20000)
    }

    var connectedRef = firebase.database().ref(".info/connected");
    connectedRef.on("value", function (snap) {
      if (snap.val() === true) {
        userRef.set({
          date: Date.now(),
          "status": 'online',
        });
      } else {
        userRef.onDisconnect().update({
          date: Date.now(),
          "status": 'offline',
        });
      }
    });


    var userRef = firebase.database().ref('users').child(this.authHelperService.getUserId());






    firebase.database().ref('users').on('value', (snapshot) => {
      var res = snapshot.val();
      this.users = [];
      var j = 0;
      for (var i in res) {
        this.users[j++] = { 'status': res[i].status, 'userId': i };
      }
      this.events.publish('userstatus');
      this.events.publish('messages');
    });




    this.events.subscribe('messages', () => {
      this.messagesToShow = [];
      this.ngZone.run(() => {
        this.messagesToShow = this.buddymessages; 
        this.usersToShow = this.users;
        setTimeout(() => {
          this.scrollToBottom()
        }, 10)
      })
    })

    this.events.subscribe('userstatus', () => {
      this.usersToShow = [];
      this.ngZone.run(() => {
        this.usersToShow = this.users;
      })
    })

  }

  scrollToBottom() { 
    // this.contentArea.scrollToBottom(100);
  }

  goBack() {
    this.navCtrl.back();
  }

  enternowdismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


  sendMessages() {
    this.chatService.messageChat(this.partnerId, this.message);
    firebase.database().ref('users').child(this.partnerId).once('value', (snapshot) => {
      let temp = snapshot.val();
      if (temp.status != 'online') {
        this.addNotifications(this.message);
      }
    });
    this.message = "";
    this.getValueWithAsync();
    setTimeout(() => {
      this.scrollToBottom()
    }, 10)
  }

  // Notification Start
  addNotifications(message) {
    var event = this.pageService.getValue('event');
    firebase.database().ref('users').child(this.partnerId).once('value', (snapshot) => {
     let temp = snapshot.val();
     if(temp.status != 'online') {
      let data = {
        date: new Date().toString(),
        message: message,  
        status: 'unread',
        type:"network",
        sender: this.authHelperService.getUserId(),
        eventId: event.id
      }
      this.pageService.addNotifications(data, this.partnerId);
     }
    });
  }

  chatCurrent(partner) {
    this.partnerId = partner.id;
    this.activeUser = partner;
    this.getValueWithAsync();
    var userRef = firebase.database().ref('users').child(this.authHelperService.getUserId());
    userRef.update({
      date: Date.now(),
      "status": 'busy',
    }); 
  }




  getValueWithAsync() {
    
    firebase.database().ref('networingchat').child(this.authHelperService.getUserId()).child(this.partnerId).on('value', (snapshot) => {
      var res = snapshot.val();
      this.buddymessages = [];
      var j = 0;
      for (var i in res) {
        this.buddymessages[j++] = res[i];
      }

      this.events.publish('messages');
    });
  }


  ionViewWillLeave() {
    var userRef = firebase.database().ref('users').child(this.authHelperService.getUserId());
    userRef.onDisconnect().update({
      "status": 'offline',
      date: Date.now(),
    });
  }


  ionViewDidEnter() {
    var userRef = firebase.database().ref('users').child(this.authHelperService.getUserId());
    userRef.update({
      date: Date.now(),
      "status": 'online',
    });
  }

 

  ngOnInit() {
    this.cuid = this.authHelperService.getUserId();
    this.events.publish('messages'); 
    this.getUserConections();
  }


  getUserConections() {
    this.chatService.getMyConnentions().subscribe(result => {
      //      this.connectionusers = result; 
      firebase.database().ref('networkingconversations').child(this.authHelperService.getUserId()).on('value', (snapshot) => {
        var res = snapshot.val();
        this.users = [];
        var j = 0; 
        result.forEach(element => { 
          this.connectionusers[j++] = { 'firebase': res[element.id], 'userInfo': element };
        });
        this.events.publish('userstatus');
        this.events.publish('messages');
        firebase.database().ref('users').on('value', (snapshot) => {
          var res1 = snapshot.val();
          for(var key in this.connectionusers){
           this.connectionusers[key]['status'] = res1[this.connectionusers[key]['userInfo'].id];
          }
        });
      });
    });
  }

}


