import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../common/components/header/header.component';
import { IonicModule } from '@ionic/angular';
import { DateAgoPipe } from '../chat/pages/chat/chat.page';
import { SafePipe } from '../common/pages/video-model/video-model.page';
import { RouterModule } from '@angular/router';
import { SliderComponent } from '../common/components/slider/slider.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ChatListComponent } from '../common/components/chat-list/chat-list.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { ErrorComponent } from './error/error.component';
import { PreviewPageComponent } from './preview-page/preview-page.component';
import { ContentEditorComponent } from './content-editor/content-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TableComponent } from './table/table.component';
import { MaterialModule } from '../material.module';
import { ChatboxComponent } from '../chat/components/chatbox/chatbox.component';
import { DeviceSelectComponent } from '../video-chat/components/settings/device-select/device-select.component';
import { NotificationListComponent } from '../common/components/notification-list/notification-list.component';
import { ExhibitorEventCategoryComponent } from './exhibitor-event-category/exhibitor-event-category.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EventCategoryComponent } from './event-category/event-category.component';



@NgModule({
  declarations: [HeaderComponent, DateAgoPipe, SafePipe, SliderComponent, ChatListComponent,
    AdminMenuComponent, NotificationListComponent, ErrorComponent, PreviewPageComponent,
    ContentEditorComponent, TableComponent, ChatboxComponent, DeviceSelectComponent,
    ExhibitorEventCategoryComponent, EventCategoryComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    SlickCarouselModule,
    AngularEditorModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    DateAgoPipe,
    SafePipe,
    SliderComponent,
    ChatListComponent,
    AdminMenuComponent,
    ErrorComponent,
    PreviewPageComponent,
    ContentEditorComponent,
    TableComponent,
    NotificationListComponent,
    ChatboxComponent,
    DeviceSelectComponent,
    ExhibitorEventCategoryComponent,
    EventCategoryComponent
  ],
  entryComponents: [EventCategoryComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule { }
