import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-orientation',
  templateUrl: './mobile-orientation.component.html',
  styleUrls: ['./mobile-orientation.component.scss'],
})
export class MobileOrientationComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
