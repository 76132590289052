import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-event-category',
  templateUrl: './event-category.component.html',
  styleUrls: ['./event-category.component.scss'],
})
export class EventCategoryComponent implements OnInit {

  @Input() eventCategories: any = [];
  choosedIndex: any = [];
  isModal: boolean = false;
  index: any = [];
  names: any = [];
  names1: any = [];

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() { }

  onCheckBoxChanged(level?: number) {
    this.choosedIndex = [];
    this.choosedIndex = this.getSelectedIndex();
  }

  getSelectedIndex() {
    this.index = [], this.names = [];
    this.eventCategories.forEach(cat => {
      if (cat.category_item.isChecked) {
        this.index.push(cat.category_item.caiId);
        this.names.push(cat.category_item.catItemName);
        this.updateChoosedCategories(cat.categories);
      }
      else {
        cat.categories.forEach(cat => {
          cat.isChecked = false;
        });
      }
    });
    return [this.index, this.names];
  }

  updateChoosedCategories(category) {
    for (let i = 0; i < category.length; i++) {
      var children = category[i].child;
      if (category[i].isChecked) {
        this.index.push(category[i].caiId);
        this.names.push(category[i].catItemName);
        if (children.length > 0)
          this.updateChoosedCategories(children);
      }
      else {
        children.forEach(cat => {
          cat.isChecked = false;
        });
      }
    }
  }

  dismiss(status) {
    this.modalCtrl.dismiss({
      'dismissed': status,
      'categories': this.choosedIndex
    });
  }

  enableCategories(category, choosedItems) {
    for (let i = 0; i < category.length; i++) {
      var children = category[i].child;
      category[i].isChecked = choosedItems.includes(category[i].caiId) ? true : false;
      if (category[i].isChecked) {
        this.names1.push(category[i].catItemName);
        if (children.length > 0)
          this.enableCategories(children, choosedItems);
      }
      else {
        children.forEach(cat => {
          cat.isChecked = false;
        });
      }
    }
  }

  checkChoosedCategories(choosedItems, eveCategories) {
    this.names1 = [];
    eveCategories.forEach(cat => {
      cat.category_item.isChecked = choosedItems.includes(cat.category_item.caiId) ? true : false;
      if (cat.category_item.isChecked) {
        this.names1.push(cat.category_item.catItemName);
        this.enableCategories(cat.categories, choosedItems);
      }
    });
    return [eveCategories, this.names1];
  }

}
