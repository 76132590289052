import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NamedRoom, VideoChatService } from '../../services/videochat.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { PageService } from 'src/app/common/services/page/page.service';
import * as firebase from 'firebase';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'app-rooms',
    templateUrl: './rooms.component.html',
    styleUrls: ['./rooms.component.scss'],
})
export class RoomsComponent implements OnInit, OnDestroy {
    @Output() roomChanged = new EventEmitter<string>();
    @Input() activeRoomName: string;

    roomName: string;
    rooms: NamedRoom[];
    exhibitorData: any;
    private subscription: Subscription;

    constructor(
        private readonly videoChatService: VideoChatService,
        private route: ActivatedRoute,
        public pageService: PageService,
        public navCtrl: NavController,
        public authHelperService: AuthHelperService,
        public router: Router, public toastService: ToastService) {

        this.exhibitorData = this.pageService.getValue('video_exhibitorData');

        this.route.queryParams.subscribe(params => {
            if (this.router.getCurrentNavigation().extras.state) {
                // this.exhibitorData = this.router.getCurrentNavigation().extras.state.exhibitorData;
                // this.pageService.storeValue(this.exhibitorData, 'video_exhibitorData');
            }

        });
        
    }
 
    async ngOnInit() {
        
 

        //this.exhibitorData.compName = "nandakumar";

        var vchat = firebase.database().ref('vchatnew').child(this.exhibitorData.compName);
        vchat.update({ 
            "status": 'created'
        }); 

        
        vchat.once('value', (snapshot) => {
            var res = snapshot.val();
 

            if (this.authHelperService.checkRole('exhibitor')) {
                if (res?.exhibitor && res?.exhibitor!=this.authHelperService.getUserId()) {
                    console.log('exhibitor alerdy booked room');

                    this.toastService.presentToast({message: 'exhibitor alerdy booked room', status: 'error' });

                    this.router.navigate(['/stall']);                    
                    
                    
                } else {
                   

                    vchat.update({
                        "exhibitor": this.authHelperService.getUserId(),
                        "status": 'hostJoined'
                    }); 
                    this.toastService.presentToast({message: 'hostJoined', status: 'success' });


                    return;
                }
            } else {
                if (this.authHelperService.checkRole('visitor')) {
                    if (res?.visitor && res?.visitor!=this.authHelperService.getUserId()) {
                        console.log('visitor alerdy booked room');
                        this.toastService.presentToast({message: 'visitor alerdy booked room', status: 'error' });
                        this.router.navigate(['/stall']);
                    } else {
                       this.onAddRoom(this.exhibitorData.compName);
                        vchat.update({
                            "visitor": this.authHelperService.getUserId(),
                            "status": 'visitorJoined'
                        });
                        this.toastService.presentToast({message: 'visitorJoined', status: 'success' });    
                        return;
                    }
                }
            }
            this.onAddRoom(this.exhibitorData.compName);
 
        }); 
 

        await this.updateRooms();
        this.subscription =
            this.videoChatService
                .$roomsUpdated
                .pipe(tap(_ => this.updateRooms()))
                .subscribe();
    }

    
    ngOnDestroy() {
        if (this.subscription) {

            

            // var vchat = firebase.database().ref('video_chat_log').child(this.exhibitorData.compName);
            // vchat.update({ 
            //     "status": 'created'
            // }); 

            this.subscription.unsubscribe();
        }
    }

    onTryAddRoom() {
        if (this.roomName) {
            this.onAddRoom(this.roomName);
        }
    }

    onAddRoom(roomName: string) {
        this.roomName = null;
        this.roomChanged.emit(roomName);
    }

    // room.on('trackRemoved', (track, participant) => {
    //     this.log(participant.identity + ' removed track: ' + track.kind);
    //     this.detachTracks([track]);
    //});

    onJoinRoom(roomName: string) {
        this.roomChanged.emit(roomName);
    }

    async updateRooms() {
        this.rooms = (await this.videoChatService.getAllRooms()) as NamedRoom[];
        if(!this.rooms){
            window.location.reload();            
        }
    }

}
