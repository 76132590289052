import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/common/services/alert/alert.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {

  public tableData = new MatTableDataSource([]);
  @Input() displayedColumns: string[];
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  @Input() tableColumns: any = [];
  @Input() rowActionIcon: string;
  @Input() set dataSource(data: any[]) {
    this.setTableDataSource(data);
  }
  @Input() showView: boolean = false;
  @Input() showDelete: boolean = false;
  @Input() showPopView: boolean = false;
  @Input() showEdit: boolean = true;
  @Input() showPublish: boolean = false;
  @Input() viewTooltip: string = 'View';

  @Output() viewItem = new EventEmitter();
  @Output() editItem = new EventEmitter();
  @Output() deleteItem = new EventEmitter();
  @Output() changeStatus = new EventEmitter();

  toolBar: any = { position: 'below', delay: 10 };

  constructor(public alertService: AlertService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.tableData.paginator = this.matPaginator;
  }

  setTableDataSource(data: any) {
    this.tableData = new MatTableDataSource<any>(data);
    this.tableData.paginator = this.matPaginator;
    this.tableData.sort = this.matSort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();
  }

  edititem(data) {
    this.editItem.emit(data);
  }

  deleteitem(key, index) {
    this.alertService.deleteAlert().then(value => {
      if (value) {
        this.deleteItem.emit({ key: key, index: index });
      }
    });
  }

  viewitem(data) {
    this.viewItem.emit(data);
  }

  changestatus(data) {
    this.changeStatus.emit(data);
  }
}