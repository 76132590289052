import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const hostUrl = environment.baseUrl;

// @Injectable({
//   providedIn: 'root'
// })
export abstract class BaseService {

  host:any;
  constructor( 
    private http: HttpClient, 
  ) {  
    this.host = localStorage.getItem('dataSource');
  }

  protected get<TData>(
    url: string,
    isProtected: boolean,
    responseType?: any,
    data?: any,
  ): Observable<TData> {
    return this.request<TData>('GET', url, isProtected, {}, data, responseType).pipe(
      map(response => response.body)
    );
  }

  protected post<TData>(
    url: string,
    isProtected: boolean,
    data: any, 
  ): Observable<TData> { 
    return this.request<TData>('POST', url, isProtected, null, data).pipe(
      map(response => response.body)
    );
  }

  protected put<TData>(
    url: string,
    isProtected: boolean,
    data: any,
  ): Observable<TData> {
    return this.request<TData>('PUT', url, isProtected, null, data).pipe(
      map(response => response.body)
    );
  }

  protected patch<TData>(
    url: string,
    isProtected: boolean,
    data: any,
  ): Observable<TData> {
    return this.request<TData>('PATCH', url, isProtected, null, data).pipe(
      map(response => response.body)
    );
  }

  protected delete<TData>(
    url: string,
    isProtected: boolean,
    data?: any,
  ): Observable<TData> {
    return this.request<TData>('DELETE', url, isProtected, null, data).pipe(
      map(response => response.body)
    );
  }

  private request<TData>(
    method: string,
    url: string,
    isProtected: boolean,
    params?: any,    
    data?: TData,
    responseType?: any,
  ): Observable<HttpResponse<TData>> {
    if (isProtected) {
      
      const token =  localStorage.getItem('token');
 
      const httpOptions = {
        responseType: null,
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin' : '*',
          Authorization: 'Bearer ' + token
        }),
        body: {},
        observe: 'response' as 'body',
        params: {}
      };
      httpOptions.body = data;      
      httpOptions.params = params;
      httpOptions.responseType = responseType
      return this.http.request<HttpResponse<TData>>(
        method,
        `${this.host}/${url}`,
        httpOptions
      );
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin' : '*',
        }),
        body: {},
        observe: 'response' as 'body',
        params: {}
      };
      httpOptions.body = data;      
      httpOptions.params = params;
      return this.http.request<HttpResponse<TData>>(
        method,
        `${this.host}/${url}`,
        httpOptions
      );
    }
  }


}