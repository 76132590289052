import { Injectable } from '@angular/core';
import { ToastController, NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import * as firebase from 'firebase';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController, public navCtrl: NavController, public authHelperService: AuthHelperService) { }
  
  cssClass: string;
  toastMessage: string;
  actionurl: string;
  cancelurl: string;

  async presentToast(param) {
    
    this.toastMessage = param.message;
    this.cssClass = ('status' in param) ? param.status : 'default';
    this.actionurl = ('actionurl' in param) ? ((param.actionurl != null && param.actionurl != '') ? param.actionurl : null): null;
    this.cancelurl = ('cancelurl' in param) ? ((param.cancelurl != null && param.cancelurl != '') ? param.cancelurl : null): null;

    if(this.actionurl != null || this.cancelurl != null)
    {
      const toast = await this.toastController.create({
        message: this.toastMessage,
        position: 'middle',
        cssClass: this.cssClass,
        buttons: [
          {
            text: 'Okay',
            handler: () => {
              console.log('Okay clicked');
            }
          }, {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }
        ]
      });
      toast.present();
    }
    else 
    {
      const toast = await this.toastController.create({
        message: this.toastMessage,
        position: 'middle',
        cssClass: this.cssClass,
        duration: 1000
      });
      toast.present();
    }
  }

  async notificationToast(data) {
    var msg = data.message;
    if(data.type == 'image' || data.type == 'pdf') {
      msg = 'Send you a attachment';
    }
    const toast = await this.toastController.create({
      header: data.sendername,
      message: msg,
      position: 'bottom',
      duration: 10000,
      buttons: [
        {
          side: 'start',
          icon: 'notifications-sharp',
          handler: () => {
            if(data.type == 'network') {
              this.goChatModel(data);
            } else {
              this.goToStall(data);
            }
            this.changeStatus(data, 'read');
          }
        }, {
          text: 'Chat Now',
          role: 'cancel',
          handler: () => {
            if(data.type == 'network') {
              this.goChatModel(data);
            } else {
              this.goToStall(data);
            }
            this.changeStatus(data, 'read');
          }
        }
      ]
    });
    toast.present();
  }

  async videoNotification(data) { 
    const toast = await this.toastController.create({
      message: data.sendername + ' request a video call',
      position: 'bottom',
      duration: 10000,
      buttons: [
        {
          side: 'start',
          icon: 'notifications-sharp',
          handler: () => {
            this.changeStatus(data, 'read');
            this.goToVideoChat(data);
          }
        }, {
          text: 'Join Now',
          role: 'cancel',
          handler: () => {
            this.changeStatus(data, 'read');
            this.goToVideoChat(data);
          }
        }
      ]
    });
    toast.present();
  }

  goChatModel(val) {
    let navigationExtras: NavigationExtras = { 
      state: {
        partneruid: val.sender
      }
    }; 
    this.navCtrl.navigateForward(['chat'], navigationExtras);
  }

  goToVideoChat(val) {
    let navigationExtras: NavigationExtras = { 
      state: {
        exhibitorData: val.exhibitorData,
        eventId: val.eventId,
        curExid: val.exhibitorData.exId,
      }
    }; 
   this.navCtrl.navigateForward(['videochat/landing'], navigationExtras);
  }

  goToStall(val) {
    let navigationExtras: NavigationExtras = { 
      state: {
        eventId: val.eventId,
        curExid: val.exhibitorId,
      }
    }; 
    this.navCtrl.navigateForward(['stall-chat'], navigationExtras);
  }

  changeStatus(val, status) {
    firebase.database().ref('notifications').child(this.authHelperService.getUserId()).once('value', (snapshot) => {
      var res = {};
      res = snapshot.val();
      for(let i in res) {
        if(res[i].status == 'unread' && (res[i].sender == val.sender || res[i].sender == val.sender['id']) && res[i].type == val.type) {
          firebase.database().ref('notifications').child(this.authHelperService.getUserId()).child(i).update({
            status: status
          });
        }
      }
    });
  }
}