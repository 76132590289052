import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, UrlSegment, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { AuthHelperService } from './common/services/auth-helper/auth-helper.service';
import { ToastService } from './common/services/toast/toast.service';
import { Location } from '@angular/common';
import { stat } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private _authHelperService: AuthHelperService,
    public toastService: ToastService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authHelperService.getToken() != null) {
      if (!this._authHelperService.checkToken()) {
        if (next.data.access == undefined || this._authHelperService.checkPermission(next.data.access)) {
          return true;
        } else {
          this.pageRedirect(state);
          return false;
        }
      }
      else {
        this.toastService.presentToast({ message: "Your session has been expired. Please login again", status: 'error' });
        localStorage.clear();
        this.router.navigate(["/login"]);
        return false;
      }
    } else {
      this.pageRedirect(state);
      return false;
      // this.toastService.presentToast({ message: "You don't have access to view this page", status: 'error' });
      // this.router.navigate(["/welcome"], { skipLocationChange: true });
    }
  }

  pageRedirect(state) {
    this.router.navigate(['/access-denied'], { skipLocationChange: true, queryParams: { url: state.url } });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

}