import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/base.service';

@Injectable({
  providedIn: 'root'
})
export class OtpService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
   }

   verifyOTP(data)
   {
    return super.post<any>('verify-code', false, data);
   }

   resendOTP(data)
   {
    return super.post<any>('resend-otp', false, data);
   }

   changeEmail(data)
   {
    return super.post<any>('user/change-email', false, data);
   }
}
