import { Component, OnInit, Pipe, Input, ElementRef, PipeTransform, Renderer2 } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../services/toast/toast.service';
import { StallService } from 'src/app/stall/services/stall.service';
import { DomSanitizer } from '@angular/platform-browser'; 
import { PageService } from '../../services/page/page.service';
import { StallPage } from 'src/app/stall/pages/stall/stall.page';


@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }   
}


@Component({
  selector: 'app-message-model',
  templateUrl: './message-model.page.html',
  styleUrls: ['./message-model.page.scss'],
})
export class MessageModelPage implements OnInit {

  activeItem: any;

  constructor(
    private modalCtrl: ModalController,
    public route:Router, public pageService: PageService,
    public stallService:StallService, 
    public toastService: ToastService) {}

  @Input() titleData: string;
  @Input() messageData: any = [];
  @Input() msgtype: string; 
  @Input() user: any; 
  @Input() showItems: boolean = true;
  @Input() multiple: boolean = false;
  @Input() data: any = [];
  @Input() exb: any = [];
  @Input() type: any;
  @Input() image: any;
  @Input() temptype: string;
  
  ngOnInit() { 
    if(this.multiple) {
      this.activeItem = this.data[0];
      this.setActive(0);
    }
    else if(this.type == 'banner' || this.type == 'standee')  {
      this.visit(this.image.fasFid);
    }
  }

  addVisit()
  {
    if(this.type == 'brochure')
    {
      this.visit(this.activeItem.pdf_fid);
    }
    else if(this.type == 'visitingcard')
    {
      this.visit(this.activeItem.fasFid);
    }
  }

  visit(assetId)
  { 

    this.stallService.saveVisit({'pageType': this.type, 'pageTypeId': this.exb.stId, 'assId': assetId}).subscribe(value => {
    }, error => {
      console.log(error);
    });
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  enternowdismiss(){
    this.modalCtrl.dismiss({
      'dismissed': true
    }); 
  }


  addBrief(ext,fId){  
    var d = {useId:	this.user.id, fId: fId, exId: ext.exId}; 
    this.stallService.brifStore(d).subscribe(value => {  
      this.toastService.presentToast({ message:'Saved to briefcase', status: 'success' });   
    }, error =>
    {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });   
    }); 
  }
 
  onPrintClicked(url)
  {
    this.pageService.printAttachment(url);
  }

  onEmailClicked(id)
  {
    this.pageService.sendAttachmentEmail(id);
  }

  setActive(index)
  {
    this.activeItem = '';
    this.activeItem = this.data[index];
    this.activeItem.pdf_atturl = this.activeItem.pdf_atturl;
    this.data.forEach(element => {
      element.active = false;
    });
    this.data[index].active = true;
    this.addVisit();
  }
}
