import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { createLocalAudioTrack, Room, LocalTrack, LocalVideoTrack, LocalAudioTrack, RemoteParticipant } from 'twilio-video';
import { RoomsComponent } from '../../components/rooms/rooms.component';
import { CameraComponent } from '../../components/camera/camera.component';
import { SettingsComponent } from '../../components/settings/settings.component';
import { ParticipantsComponent } from '../../components/participants/participants.component';
import { NavigationExtras } from '@angular/router';
import { VideoChatService } from '../../services/videochat.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { PageService } from 'src/app/common/services/page/page.service';
import * as firebase from 'firebase';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { NavController, ModalController } from '@ionic/angular';


@Component({
    selector: 'app-landing',
    templateUrl: './landing.page.html',
    styleUrls: ['./landing.page.scss'],
})
export class LandingPage implements OnInit {

    @Input() userid: any;
    @Input() comapyname: any;

    @ViewChild('rooms') rooms: RoomsComponent;
    @ViewChild('camera') camera: CameraComponent;
    @ViewChild('settings') settings: SettingsComponent;
    @ViewChild('participants') participants: ParticipantsComponent;

    activeRoom: Room;

    logo: any;

    muiteflag: any;

    event: any;

    devisstate: any;

    exhibitorData: any;
    curExid: any;
    eventId: any;
    constructor(
        private readonly videoChatService: VideoChatService,
        private route: ActivatedRoute,
        public navCtrl: NavController,
        public pageService: PageService,
        private modalCtrl: ModalController,
        public authHelperService: AuthHelperService,
        public router: Router, public toastService: ToastService) {

        this.onLeaveRoom(true);

        this.route.queryParams.subscribe(params => {
            // if (this.router.getCurrentNavigation().extras.state) {

            //     this.exhibitorData = this.router.getCurrentNavigation().extras.state.exhibitorData;
            //     this.eventId = this.router.getCurrentNavigation().extras.state.eventId;
            //     this.curExid = this.router.getCurrentNavigation().extras.state.curExid;

            //     this.pageService.storeValue(this.exhibitorData, 'video_exhibitorData');
            //     this.pageService.storeValue(this.eventId, 'video_eventId');
            //     this.pageService.storeValue(this.curExid, 'video_curExid');


            // } else {
            //     this.exhibitorData = this.pageService.getValue('video_exhibitorData');
            //     this.eventId = this.pageService.getValue('video_eventId');
            //     this.curExid = this.pageService.getValue('video_curExid');

            // }
        });


        navigator
            .mediaDevices
            .getUserMedia({ video: true, audio: true })
            .then(stream => {
                this.devisstate = 'working';
            })
            .catch(e => {
                this.devisstate = e;
                console.log(e);
            });




    }





    async ngOnInit() {

        // console.log(this.userid);

        ///this.comapyname.compName

        setTimeout(function () {
            document.getElementById("lvbtn").click();
        }, 100000);




        setTimeout(function () {
            document.getElementById("jbtn").click();
        }, 2000);



        this.onRoomChanged(this.userid);

        // this.pageService.getSiteInfo().then(value => {
        //     this.logo = value.logo;
        // });

        // this.event = this.pageService.getValue('event');
        // await this.rooms.updateRooms();

    }

    // ionViewWillLeave() {
    //     if (this.activeRoom) {



    //         var vchat = firebase.database().ref('vchatnew').child(this.activeRoom.name);

    //         vchat.once('value', (snapshot) => {
    //             var res = snapshot.val();
    //             if (this.authHelperService.checkRole('exhibitor')) {
    //                 vchat.child('exhibitor').remove();
    //                 this.toastService.presentToast({ message: 'you left the meeting', status: 'success' });
    //                 this.activeRoom.disconnect();
    //                 this.router.navigate(['/stall']);

    //             } else {
    //                 if (this.authHelperService.checkRole('visitor')) {
    //                     vchat.child('visitor').remove();
    //                     this.toastService.presentToast({ message: 'you left the meeting', status: 'success' });
    //                     this.router.navigate(['/stall']);
    //                 }
    //             }
    //             vchat.update({
    //                 "endtime": new Date().toString()
    //             });

    //         });
    //     }
    // }

    async onSettingsChanged(deviceInfo?: MediaDeviceInfo) {
        await this.camera.initializePreview(deviceInfo.deviceId);
        if (this.settings.isPreviewing) {
            const track = await this.settings.showPreviewCamera();
            if (this.activeRoom) {
                this.pageService.storeValue('opens', 'videpen');
                const localParticipant = this.activeRoom.localParticipant;
                console.log(this.activeRoom);
                localParticipant.videoTracks.forEach(publication => publication.unpublish());
                await localParticipant.publishTrack(track);
            }
        }
    }

    async onLeaveRoom(_: boolean) {
        if (this.activeRoom) {

            var vchat = firebase.database().ref('vchatnew').child(this.activeRoom.name);

            console.log(this.activeRoom.participants);
            console.log(this.activeRoom.localParticipant);
            console.log(this.activeRoom.getMaxListeners);

            var sid = this.activeRoom.sid;
            this.activeRoom.localParticipant.videoTracks.forEach(publication => {
                publication.track.stop();
                const attachedElements = publication.track.detach();
                console.log("unsubscribed from: " + publication.track)
                attachedElements.forEach(element => element.remove());
            });

            this.activeRoom.disconnect();
            this.activeRoom = null;

            this.modalCtrl.dismiss({
                'dismissed': true,
                'roomid': sid,
                'exid': this.comapyname
            });





            // vchat.once('value', (snapshot) => {
            //     var res = snapshot.val();
            //     if (this.authHelperService.checkRole('exhibitor')) {

            //         vchat.child('exhibitor').remove();
            //         this.toastService.presentToast({ message: 'you left the meeting', status: 'success' });

            //         this.router.navigate(['/stall']);

            //     } else {
            //         if (this.authHelperService.checkRole('visitor')) {
            //             vchat.child('visitor').remove();
            //             this.toastService.presentToast({ message: 'you left the meeting', status: 'success' });
            //             this.router.navigate(['/stall']);
            //         }
            //     }

            //     vchat.update({
            //         "endtime": new Date().toString()
            //     });

            // });


        }

        const videoDevice = this.settings.hidePreviewCamera();
        await this.camera.initializePreview(videoDevice && videoDevice.deviceId);

        this.participants.clear();
    }

    async onRoomChanged(roomName: string) {

        if (roomName) {
            if (this.activeRoom) {
                this.activeRoom.disconnect();
            }

            this.camera?.finalizePreview();

            const tracks = await Promise.all([
                createLocalAudioTrack(),
                this.settings?.showPreviewCamera()
            ]);

            this.activeRoom =
                await this.videoChatService
                    .joinOrCreateRoom(roomName, tracks);

            this.participants?.initialize(this.activeRoom?.participants);
            this.registerRoomEvents();

            //this.notificationHub.send('RoomsUpdated', true);
        }
    }

    onParticipantsChanged(_: boolean) {
        this.videoChatService.nudge();
    }

    private registerRoomEvents() {
        this.activeRoom
            .on('disconnected',
                (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
            .on('participantConnected',
                (participant: RemoteParticipant) => this.participants.add(participant))
            .on('participantDisconnected',
                (participant: RemoteParticipant) => this.participants.remove(participant))
            .on('dominantSpeakerChanged',
                (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
    }

    private detachLocalTrack(track: LocalTrack) {
        if (this.isDetachable(track)) {

            track.detach().forEach(el => el.remove());
        }
    }

    private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
        return !!track
            && ((track as LocalAudioTrack).detach !== undefined
                || (track as LocalVideoTrack).detach !== undefined);
    }

    muite() {
        this.activeRoom.localParticipant.audioTracks.forEach(function (trackId, track) {
            trackId.track.disable();

        });

        this.muiteflag = true;
    }

    unmuite() {
        this.activeRoom.localParticipant.audioTracks.forEach(function (trackId, track) {
            trackId.track.enable();
        });


        this.muiteflag = false;
    }


    goBack() {
        location.reload();
        //
    }

    closePopup() {
        this.modalCtrl.dismiss();
    }

    ngOnDestroy() {
        console.log('landing dis');
    }
}
