import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService extends BaseService {

  constructor(http: HttpClient) { 
    super(http);
  }

  resetPassword(data) 
  {
    return super.post<any>('resetpassword', true, data);
  }
}
