import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { PageService } from '../page/page.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public alertController: AlertController, public authHelperService: AuthHelperService,
    public toastService: ToastService, public pageService: PageService) { }

  async logoutAlert() {
    const alert = await this.alertController.create({
      header: 'Confirm!',
      message: 'Are you sure you want to logout from this application?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: 'Yes',
          handler: () => {
            this.pageService.logout(this.authHelperService.getUserId()).subscribe(value => {
            }, error => {
              console.log(error);
            });
            this.authHelperService.logout();
          }
        }
      ]
    });
    await alert.present();
  }

  async deleteAlert() {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Confirm!',
        message: 'Are you sure you want to delete this item?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {
              resolve(false);
            }
          }, {
            text: 'Yes',
            handler: (ok) => {
              resolve(true);
            }
          }
        ]
      });
      alert.present();
    });
  }

  async confirmAlert(data) {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Confirm!',
        message: data.message,
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (cancel) => {
              resolve(false);
            }
          }, {
            text: 'Yes',
            handler: (ok) => {
              resolve(true);
            }
          }
        ]
      });
      alert.present();
    });
  }

  async inputAlert(data) {
    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        backdropDismiss: false,
        header: data.header,
        inputs: [
          {
            name: 'input_one',
            type: 'text',
            placeholder: data.placeholder
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              resolve(false);
            }
          }, {
            text: 'Submit',
            handler: (data) => {
              if (data.input_one == '' || data.input_one == null || data.input_one == undefined)
                this.toastService.presentToast({ message: 'Please fill all fields' });
              else
                resolve(data.input_one);
            }
          }
        ]
      });
      alert.present();
    });
  }

}