import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast/toast.service';
import { LoginService } from 'src/app/login/services/login.service';
import { PageService } from '../../services/page/page.service';
import { AuthHelperService } from '../../services/auth-helper/auth-helper.service';

@Component({
  selector: 'app-globalfeedback',
  templateUrl: './globalfeedback.component.html',
  styleUrls: ['./globalfeedback.component.scss'],
})
export class GlobalfeedbackComponent implements OnInit {

  @Input() temptype: any;
  feedbackForm: FormGroup;
  hdQuestions: any = [];

  constructor(private formBuilder: FormBuilder, 
    private modalCtrl: ModalController,
    public router: Router, public toastService: ToastService, public loginService:LoginService, 
    public authHelperService: AuthHelperService, public pageService: PageService) {

      this.feedbackForm = this.formBuilder.group({
  
      });
 

  } 

  onSubmitClicked(){
    this.toastService.presentToast({ message:'Thanks for your Time and Valuable Feedback!', status: 'sucess' });  
    this.dismiss(); 
  }

  onSendClicked(){
    this.toastService.presentToast({ message:'Submitted successfully', status: 'sucess' });  
    this.dismiss(); 
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  onHelpdeskSubmit()
  {

  }

  ngOnInit() {
    this.pageService.getHelpDesk().subscribe(value => {
      this.hdQuestions = value.data;
      console.log(this.hdQuestions);
    }, error => {
      console.log(error);
    });
  }

}
