import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { formatDate } from '@angular/common';
import { StallService } from 'src/app/stall/services/stall.service';
import { ToastService } from '../../services/toast/toast.service';
import { LoadingService } from '../../services/loading/loading.service';
import * as _moment from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthHelperService } from '../../services/auth-helper/auth-helper.service';

const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-meeting-scheduler',
  templateUrl: './meeting-scheduler.page.html',
  styleUrls: ['./meeting-scheduler.page.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MeetingSchedulerPage implements OnInit {

  @Input() stId: number;
  @Input() evId: number;
  @Input() isMyStall: boolean = false;
  @Input() stallName: string;
  meetingForm: FormGroup;
  minDate: any;
  maxDate: any;
  minTime: any;
  timeSlots: any = [];
  avTimeSlots: any = [];
  meetingData: any = [];
  data: boolean = false;
  today: any;
  curTime: any;
  userId: any;
  meetUrl: string = 'https://us02web.zoom.us/j/';

  constructor(public formBuilder: FormBuilder, public modalCtrl: ModalController, public stallService: StallService,
    public toastService: ToastService, public loadingservice: LoadingService, public authHelperService: AuthHelperService) {
    this.minDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.minTime = this.getRoundedTime();
    this.meetingForm = this.formBuilder.group({
      date: ['', Validators.required],
      time: ['', Validators.required]
    });
    this.userId = this.authHelperService.getUserId();
  }

  ngOnInit() {
    this.getInitData();
  }

  getInitData() {
    this.loadingservice.present();
    this.stallService.getScheduledMeetings(this.evId, this.stId).subscribe(value => {
      this.loadingservice.dismiss();
      this.data = true;
      this.meetingData = value.data;
      this.today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      this.curTime = formatDate(new Date(), 'HH:mm', 'en');
      if (this.meetingData.event.start_date <= this.today) {
        this.minDate = this.today;
      } else if (this.meetingData.event.start_date > this.today) {
        this.minDate = this.meetingData.event.start_date;
      }
      // this.maxDate = this.meetingData.event.end_date;
    }, error => {
      this.loadingservice.dismiss();
      // this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  onChooseClicked() {
    if (this.isMyStall) {
      this.toastService.presentToast({ 'message': "You can't schedule meeting for your own stall" });
    } else {
      this.meetingForm.value.date = this.meetingForm.value.date.format("YYYY-MM-DD");
      this.meetingForm.value.start_time = this.meetingForm.value.time.start_time;
      this.meetingForm.value.end_time = this.meetingForm.value.time.end_time;
      this.meetingForm.value.stId = this.stId;
      this.loadingservice.present();
      this.stallService.saveMeeting(this.meetingForm.value).subscribe(value => {
        this.loadingservice.dismiss();
        this.toastService.presentToast({ message: value.message, status: 'success' });
        this.dismiss();
      }, error => {
        this.loadingservice.dismiss();
        // this.toastService.presentToast({ message: error.error.message, status: 'error' });
      });
    }
  }

  onDateChanged() {
    var chDate = this.meetingForm.value.date.format("YYYY-MM-DD");
    var meets = this.meetingData.meets.filter(opt => (opt.date == chDate)).map(opt => opt);
    this.getTimeSlots(meets, chDate);
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  onConfirmClicked(meet) {
    this.loadingservice.present();
    this.stallService.createZoomMeet({ meetId: meet.msId }).subscribe(value => {
      this.loadingservice.dismiss();
      this.toastService.presentToast({ message: value.message, status: 'success' });
      this.getInitData();
    }, error => {
      console.log(error);
      this.loadingservice.dismiss();
      // this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  getRoundedTime() {
    var d = new Date();
    var ratio = d.getMinutes() / 60;
    var hour = d.getHours();
    var time;
    if (ratio >= 0.4) {
      hour = hour + 1;
      time = ':00';
    }
    else if (ratio < 0.4) {
      hour = hour;
      time = ':30';
    }
    var h = (hour < 10) ? ('0' + hour) : hour;
    return h + time;
  }

  getTimeSlots(meets, date) {
    var chTimeSlots = meets;
    var array = chTimeSlots.filter(opt => opt.start_time).map(opt => opt.start_time);
    this.timeSlots = this.time();
    if (date == this.minDate) {
      this.avTimeSlots = this.timeSlots.filter(opt => ((opt.start_time >= this.minTime) && !array.includes(opt.start_time))).map(opt => opt);
    }
    else {
      this.avTimeSlots = this.timeSlots.filter(opt => (!array.includes(opt.start_time))).map(opt => opt);
    }
  }

  time() {
    return [
      { 'start_time': '01:00', 'end_time': '01:30' }, { 'start_time': '01:30', 'end_time': '02:00' },
      { 'start_time': '02:00', 'end_time': '02:30' }, { 'start_time': '02:30', 'end_time': '03:00' },
      { 'start_time': '03:00', 'end_time': '03:30' }, { 'start_time': '03:30', 'end_time': '04:00' },
      { 'start_time': '04:00', 'end_time': '04:30' }, { 'start_time': '04:30', 'end_time': '05:00' },
      { 'start_time': '05:00', 'end_time': '05:30' }, { 'start_time': '05:30', 'end_time': '06:00' },
      { 'start_time': '06:00', 'end_time': '06:30' }, { 'start_time': '06:30', 'end_time': '07:00' },
      { 'start_time': '07:00', 'end_time': '07:30' }, { 'start_time': '07:30', 'end_time': '08:00' },
      { 'start_time': '08:00', 'end_time': '08:30' }, { 'start_time': '08:30', 'end_time': '09:00' },
      { 'start_time': '09:00', 'end_time': '09:30' }, { 'start_time': '09:30', 'end_time': '10:00' },
      { 'start_time': '10:00', 'end_time': '10:30' }, { 'start_time': '10:30', 'end_time': '11:00' },
      { 'start_time': '11:00', 'end_time': '11:30' }, { 'start_time': '11:30', 'end_time': '12:00' },
      { 'start_time': '12:00', 'end_time': '12:30' }, { 'start_time': '12:30', 'end_time': '13:00' },
      { 'start_time': '13:00', 'end_time': '13:30' }, { 'start_time': '13:30', 'end_time': '14:00' },
      { 'start_time': '14:00', 'end_time': '14:30' }, { 'start_time': '14:30', 'end_time': '15:00' },
      { 'start_time': '15:00', 'end_time': '15:30' }, { 'start_time': '15:30', 'end_time': '16:00' },
      { 'start_time': '16:00', 'end_time': '16:30' }, { 'start_time': '16:30', 'end_time': '17:00' },
      { 'start_time': '17:00', 'end_time': '17:30' }, { 'start_time': '17:30', 'end_time': '18:00' },
      { 'start_time': '18:00', 'end_time': '18:30' }, { 'start_time': '18:30', 'end_time': '19:00' },
      { 'start_time': '19:00', 'end_time': '19:30' }, { 'start_time': '19:30', 'end_time': '20:00' },
      { 'start_time': '20:00', 'end_time': '20:30' }, { 'start_time': '20:30', 'end_time': '21:00' },
      { 'start_time': '21:00', 'end_time': '21:30' }, { 'start_time': '21:30', 'end_time': '22:00' },
      { 'start_time': '22:00', 'end_time': '22:30' }, { 'start_time': '22:30', 'end_time': '23:00' },
      { 'start_time': '23:00', 'end_time': '23:30' }, { 'start_time': '23:30', 'end_time': '24:00' }
    ];
  }

}