import { Component, OnInit, Input, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FileService } from '../services/file.service';
import { ToastController, LoadingController, ModalController } from '@ionic/angular';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IonInfiniteScroll } from '@ionic/angular';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-file',
  templateUrl: './file.page.html',
  styleUrls: ['./file.page.scss'],
})
export class FilePage implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Input() chooseFile: boolean = true;
  @Input() postLogin: boolean;
  segment: string = 'upload-file';
  files: any = [];
  selectedFiles: File;
  selectedIndex: any = [];
  image: any;
  user: any = [];
  items = [];

  constructor(public fileService: FileService, public pageService: PageService, public toastController: ToastController,
    public loadingController: LoadingController, public toastService: ToastService,
    public loadingService: LoadingService, public modalCtrl: ModalController) { }

  ngOnInit() {
    this.getFiles();
    if (this.postLogin)
      this.getUserProfile();
  }

  loadData(event) {
    setTimeout(() => {
      if (this.files.length <= this.items.length) {
        var limit = this.files.length + 20, inital = this.files.length + 1;
        limit = (this.items.length < limit) ? this.items.length : limit;
        for (let i = inital; i <= limit; i++) {
          if (this.items[i] != undefined)
            this.files.push(this.items[i]);
        }
      }
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.files.length == this.items.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  getUserProfile() {
    this.pageService.getUserProfile().then(value => {
      this.user = value;
    });
  }

  onFileChange(event) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.image = reader.result;
    }
    this.selectedFiles = event.target.files[0];
  }

  getFiles() {
    this.fileService.getFiles().subscribe(value => {
      this.items = value;
      for (let i = 0; i < 20; i++) {
        this.files.push(this.items[i]);
      }
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  onUploadFilesClicked() {
    var myFormData = new FormData();
    myFormData.append('attachment', this.selectedFiles);
    if (this.chooseFile) {
      this.loadingService.present();
      this.fileService.uploadFile(myFormData).subscribe(result => {
        this.getFiles();
        this.loadingService.dismiss();
        this.toastService.presentToast({ message: result.message, status: 'success' });
        this.modalCtrl.dismiss({
          'fileId': result.data,
          'image': this.image
        });
      }, error => {
        this.loadingService.dismiss();
        this.toastService.presentToast({ message: error.error.message, status: 'error' });
      });
    }
    else {
      this.modalCtrl.dismiss({
        'file': this.selectedFiles,
        'image': this.image
      });
    }
  }

  OnChooseFileClicked() {
    this.selectedIndex = this.files.filter(opt => opt.isChecked).map(opt => opt.fId);
    this.image = this.files.filter(opt => opt.isChecked).map(opt => opt.atta_url);
    this.modalCtrl.dismiss({
      'fileId': this.selectedIndex[0],
      'image': this.image[0]
    });
  }

  onCheckboxChanged(id) {
    this.selectedIndex = id;
    this.files.forEach(element => {
      element.isChecked = false;
    });
  }

  onImageClicked(id) {
    this.onCheckboxChanged(id);
    this.files.find(v => v.fId == id).isChecked = true;
  }

}