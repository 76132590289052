import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';

interface AuthToken {
  token: string;
}


export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
  providedIn: 'root'
})
export class VideoChatService { 
  $roomsUpdated: Observable<boolean>;

  private roomBroadcast = new ReplaySubject<boolean>();

  constructor(private readonly http: HttpClient, public authHelperService: AuthHelperService) {
    this.$roomsUpdated = this.roomBroadcast.asObservable();

  }

  private async getAuthToken() {
    const auth =
      await this.http
        .get<AuthToken>(localStorage.getItem('dataSource') + '/video/gettoken/' + this.authHelperService.getUserId())
        .toPromise();
    return auth.token;
  }

  getAllRooms() {
    return this.http
      .get<Rooms>(localStorage.getItem('dataSource') + '/videochat')
      .toPromise();
  }

  async joinOrCreateRoom(name: string, tracks: LocalTrack[]) {

    // let room: Room = null;
    // const token = await this.getAuthToken();


    // connect(token, {name, tracks, dominantSpeaker: true}).then(rooms => {

    //   //   console.log(rooms.participants)

    //   //   room.on('participantConnected', participant => {
    //   //     console.log(participant, 'participant');
    //   //   });
    //   // }, error => {
    //   //   console.error(`Unable to connect to Room: ${error.message}`);
    //   // });

    // connect(token, {name, tracks, dominantSpeaker: true} as ConnectOptions).then(value => {
    //     console.log(value.localParticipant.identity);
    //     console.log(value.localParticipant.state);
    //     this.roomBroadcast.next(true);
    //   });

    let room: Room = null;
    try {
      const token = await this.getAuthToken();
      room =
        await connect(
          token, {
            name,
            tracks,
            dominantSpeaker: true
          } as ConnectOptions);

    } catch (error) {
      console.error(`Unable to connect to Room: ${error.message}`);
    } finally {
      if (room) {
        this.roomBroadcast.next(true);
      }
    }

    return room;
  }

  nudge() {
    this.roomBroadcast.next(true);
  }
}
