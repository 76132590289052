import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ToastService } from '../../services/toast/toast.service';
import { StallService } from 'src/app/stall/services/stall.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage implements OnInit {

  feedbackForm: FormGroup;
  @Input() stall: any;
  @Input() type: any;

  constructor(private formBuilder: FormBuilder, private modalCtrl: ModalController,
              public toastService: ToastService, public stallService: StallService) 
  {
    this.feedbackForm = this.formBuilder.group({
      type: ['', Validators.required],
      description: [''],
    });
  } 

  ngOnInit() {
  }

  onSubmitClicked()
  {
    this.feedbackForm.value.stId = this.stall;
    this.toastService.presentToast({ message:'Thank you for your feedback' });  
    this.dismiss();
    this.stallService.saveFeedback(this.feedbackForm.value).subscribe(value => {
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
