import { NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LandingPageRoutingModule } from './landing-routing.module';

import { LandingPage } from './landing.page';
import { RoomsComponent } from '../../components/rooms/rooms.component';
import { CameraComponent } from '../../components/camera/camera.component';
import { SettingsComponent } from '../../components/settings/settings.component';
import { ParticipantsComponent } from '../../components/participants/participants.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LandingPageRoutingModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  declarations: [LandingPage, RoomsComponent, CameraComponent, SettingsComponent, ParticipantsComponent]
})
export class LandingPageModule {}
